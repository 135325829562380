var render = function render(){
  var _vm$user$access, _vm$user$access$featu, _vm$user$access$featu2, _vm$user$access$featu3, _vm$user$access2, _vm$user$access2$feat, _vm$user$access2$feat2, _vm$user$access2$feat3, _vm$user$access3, _vm$user$access3$feat, _vm$user$access3$feat2, _vm$user$access3$feat3, _vm$form$meta, _vm$form$meta$images, _vm$form$meta$images$, _vm$form$meta2, _vm$form$meta2$images, _vm$form$meta2$images2, _vm$form$meta3, _vm$form$meta3$images, _vm$form$meta3$images2, _vm$form$meta4, _vm$form$meta4$images, _vm$form$meta4$images2, _vm$form$meta5, _vm$form$meta5$images, _vm$form$images, _vm$form$meta6, _vm$form$meta6$images, _vm$form$images2, _vm$form$meta7, _vm$form$meta7$images, _vm$form$images3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: 'listing-add-' + _vm.updateHTML
  }, [_vm.objectSelectionOpen && _vm.chosenLinkedDeal.objects && _vm.chosenLinkedDeal.objects.length > 0 ? _c('ObjectSelection', {
    attrs: {
      "project": _vm.chosenLinkedDeal,
      "position": _vm.position
    },
    on: {
      "closed": function ($event) {
        _vm.objectSelectionOpen = false;
      },
      "objectSelected": _vm.chooseObject
    }
  }) : _vm._e(), _vm.projectAddOpen ? _c('Sidepanel', {
    staticStyle: {
      "z-index": "11"
    },
    attrs: {
      "no-refresh": true,
      "from-add-listing": true
    },
    on: {
      "saveResponse": _vm.handleNewDeal,
      "closed": _vm.closeProjectAdd
    }
  }) : _vm._e(), _c('div', {
    staticClass: "sticky",
    staticStyle: {
      "display": "flex",
      "height": "64px",
      "width": "100%",
      "z-index": "10",
      "padding-left": "156px",
      "align-items": "center"
    }
  }, [_c('div', {
    staticClass: "add-button",
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      "click": function ($event) {
        return _vm.saveListing(_vm.form, true);
      }
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "30px",
      "height": "30px"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('img', {
    staticStyle: {
      "margin-right": "9px"
    },
    attrs: {
      "alt": "",
      "src": require("../../assets/images/checkmarkCircled.svg")
    }
  }) : _vm._e(), !_vm.isLoading ? _c('p', [_vm._v(_vm._s(_vm.$t("SaveAndExit")))]) : _vm._e()]), _c('div', {
    staticClass: "cancel",
    staticStyle: {
      "margin-right": "12px",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.saveListing(_vm.form, false);
      }
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "30px",
      "height": "30px"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('p', [_vm._v(_vm._s(_vm.$t("Save")))]) : _vm._e()]), _c('button', {
    staticClass: "cancel padding-top",
    staticStyle: {
      "margin-right": "16px"
    },
    on: {
      "click": function ($event) {
        return _vm.cancel();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _vm.edit ? _c('div', {
    staticStyle: {
      "margin-right": "16px"
    }
  }, [_vm._v("•")]) : _vm._e(), _vm.edit ? _c('div', [_c('button', {
    staticClass: "delete-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": _vm.openDeleteModal
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/TrashBin.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "header",
    staticStyle: {
      "margin-top": "129px",
      "padding-top": "0"
    }
  }, [!_vm.edit ? _c('h3', [_vm._v(_vm._s(_vm.$t("AddNewListing")))]) : _vm._e(), _vm.edit ? _c('h3', [_vm._v(_vm._s(_vm.$t("EditListing")))]) : _vm._e(), _c('p', {
    staticClass: "statusLabel content-bold",
    class: {
      clickable: _vm.edit,
      'draft-label ': _vm.form.status === 'draft',
      'active-label ': _vm.form.status === 'active',
      'booked-label': _vm.form.status === 'booked',
      'sold-label ': _vm.form.status === 'sold',
      'archived-label': _vm.form.status === 'archived',
      'rented-label': _vm.form.status === 'rented',
      'expired-label': _vm.form.status === 'expired'
    },
    attrs: {
      "id": "statusLabel"
    },
    on: {
      "click": function ($event) {
        return _vm.openStatusDropdown(_vm.form);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.status(_vm.form.status)) + " ")]), _c('v-tooltip', {
    key: _vm.bookingUpdater,
    attrs: {
      "bottom": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_vm.form.status === 'booked' ? _c('p', _vm._g(_vm._b({
          staticClass: "booked-label booked-info-label"
        }, 'p', attrs, false), on), [_c('img', {
          staticStyle: {
            "filter": "invert(1)"
          },
          attrs: {
            "src": require("../../assets/images/info.svg"),
            "alt": ""
          },
          on: {
            "click": function ($event) {
              return _vm.openBookingDetailsModal(_vm.form);
            }
          }
        })]) : _vm._e()];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("BookingDetails")))])]), _vm.form.status === 'active' && _vm.form.dateAvailable && !_vm.form.activeUntil ? _c('p', {
    staticClass: "content-small",
    staticStyle: {
      "margin-left": "8px",
      "cursor": "pointer"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("available")) + " " + _vm._s(_vm.formatDate(_vm.form.dateAvailable)) + " ")]) : _vm._e(), _vm.form.status === 'booked' && _vm.form.bookedUntil ? _c('p', {
    staticClass: "content-small booking-until-date",
    staticStyle: {
      "margin-left": "8px",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.openBookingDetailsModal(_vm.form);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("until")) + " " + _vm._s(_vm.formatDate(_vm.form.bookedUntil)) + " ")]) : _vm._e(), _vm.form.status === 'active' && _vm.form.activeUntil ? _c('date-pick', {
    staticStyle: {
      "display": "flex"
    },
    attrs: {
      "isDateDisabled": _vm.isFutureDate
    },
    on: {
      "input": function ($event) {
        return _vm.changeActiveUntil(_vm.form, $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var toggle = _ref2.toggle;
        return [_vm.form.status === 'active' && _vm.form.activeUntil ? _c('p', {
          staticClass: "content-small",
          staticStyle: {
            "margin-left": "8px",
            "cursor": "pointer"
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return toggle.apply(null, arguments);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("until")) + " " + _vm._s(_vm.form.activeUntil) + " ")]) : _vm._e()];
      }
    }], null, false, 1552725545)
  }) : _vm._e()], 1), _c('div', {
    staticClass: "content"
  }, [!_vm.user.isItaly ? _c('div', {
    staticStyle: {
      "display": "flex",
      "max-height": "48px",
      "height": "48px",
      "background": "white",
      "margin-bottom": "32px",
      "max-width": "960px",
      "border-radius": "8px",
      "padding": "8px 16px 0 16px"
    }
  }, _vm._l(_vm.activeMarketplaces, function (amount, name, index) {
    return _c('div', {
      key: name,
      staticClass: "active-marketplaces",
      staticStyle: {
        "display": "flex"
      }
    }, [_c('v-checkbox', {
      attrs: {
        "color": "black",
        "disabled": _vm.form.addressType === 'generic' && name !== 'www' || name === 'kv' && _vm.disableKv
      },
      model: {
        value: _vm.form.marketplaces.find(function (marketplace) {
          return marketplace.name === name;
        }).isActive,
        callback: function ($$v) {
          _vm.$set(_vm.form.marketplaces.find(function (marketplace) {
            return marketplace.name === name;
          }), "isActive", $$v);
        },
        expression: "\n            form.marketplaces.find((marketplace) => marketplace.name === name)\n              .isActive\n          "
      }
    }), _c('div', {
      staticStyle: {
        "padding-top": "6px"
      }
    }, [_c('span', {
      staticStyle: {
        "padding-right": "4px"
      }
    }, [_vm._v(_vm._s(name))]), _c('span', {
      staticClass: "content-bold"
    }, [_vm._v(_vm._s(amount.active))]), _c('span', [_vm._v("/ " + _vm._s(amount.total))])]), index != Object.keys(_vm.activeMarketplaces).length - 1 ? _c('div', {
      staticStyle: {
        "margin-left": "16px",
        "margin-right": "16px",
        "padding-top": "4px"
      }
    }, [_vm._v(" • ")]) : _vm._e()], 1);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "section-row"
  }, [_c('v-row', {
    staticClass: "subheading"
  }, [_c('div', {
    staticClass: "circle-number",
    staticStyle: {
      "padding-top": "1px"
    }
  }, [_vm._v("1")]), _c('h5', [_vm._v(_vm._s(_vm.$t("AddProjectAndObjectDetails")))])]), _vm.edit && _vm.deal || _vm.deal && _vm.addToProject ? _c('v-row', {
    staticClass: "autocomplete-frame",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "deal-name"
  }, [_vm._v(" " + _vm._s(_vm.deal.name) + " ")]), _c('v-row', {
    staticClass: "deal-name-item",
    on: {
      "click": function ($event) {
        return _vm.openDeal(_vm.deal);
      }
    }
  }, [_vm._v("View")])], 1) : _vm._e(), !_vm.edit && !_vm.addToProject ? _c('v-row', {
    staticClass: "autocomplete-frame",
    staticStyle: {
      "width": "100%"
    }
  }, [_vm.$route.params.referenceDealId && _vm.$route.params.project._id ? _c('v-row', {
    staticClass: "deal-name"
  }, [_vm._v(" " + _vm._s(_vm.chosenLinkedDealName) + " ")]) : _vm._e(), !_vm.$route.params.referenceDealId && (!_vm.user.config || _vm.user.config && (_vm$user$access = _vm.user.access) !== null && _vm$user$access !== void 0 && (_vm$user$access$featu = _vm$user$access.features) !== null && _vm$user$access$featu !== void 0 && (_vm$user$access$featu2 = _vm$user$access$featu.modules) !== null && _vm$user$access$featu2 !== void 0 && (_vm$user$access$featu3 = _vm$user$access$featu2.projects) !== null && _vm$user$access$featu3 !== void 0 && _vm$user$access$featu3.enabled) ? _c('v-row', {
    staticClass: "autocomplete-row"
  }, [_c('v-row', {
    staticClass: "required"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/projects.svg"),
      "alt": ""
    }
  }), _c('h6', {
    staticClass: "content-bold"
  }, [_vm._v(_vm._s(_vm.$t("AddProject")))])]), _c('v-row', {
    staticStyle: {
      "justify-content": "flex-start",
      "align-items": "center",
      "gap": "32px",
      "margin-top": "16px"
    }
  }, [_c('Autocomplete', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeMenu,
      expression: "closeMenu"
    }],
    class: 'autocomplete-item ' + (_vm.isErrorDeal ? 'not-valid' : ''),
    attrs: {
      "placeholder": _vm.$t('AddProject'),
      "items": [],
      "items-per-page": 25,
      "search-key": "name",
      "display-key": "name",
      "searching": true,
      "close-menu": _vm.closeDealMenu,
      "written-text": _vm.chosenLinkedDealName,
      "error-key": _vm.isErrorDeal
    },
    on: {
      "resultChosen": _vm.setRelatedDeal,
      "addNew": _vm.openProjectAdd,
      "removeResult": _vm.clearDeal
    }
  }), _vm.chosenLinkedDeal ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "justify-content": "flex-start",
      "gap": "4px",
      "align-items": "center"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("ProjectType")) + ":")]), _c('p', {
    class: {
      'content-small-green green-font': _vm.chosenLinkedDeal.dealType == 'Sale',
      'content-small-pink pink-font': _vm.chosenLinkedDeal.dealType == 'Lease out' || _vm.chosenLinkedDeal.dealType == 'LeaseOut'
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDealType()) + " ")])]) : _vm._e()], 1), _vm.isErrorDeal ? _c('v-row', {
    staticClass: "error-text",
    staticStyle: {
      "margin-top": "8px"
    },
    model: {
      value: _vm.isErrorDeal,
      callback: function ($$v) {
        _vm.isErrorDeal = $$v;
      },
      expression: "isErrorDeal"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("FormErrors.FieldRequired")) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.user.config && !((_vm$user$access2 = _vm.user.access) !== null && _vm$user$access2 !== void 0 && (_vm$user$access2$feat = _vm$user$access2.features) !== null && _vm$user$access2$feat !== void 0 && (_vm$user$access2$feat2 = _vm$user$access2$feat.modules) !== null && _vm$user$access2$feat2 !== void 0 && (_vm$user$access2$feat3 = _vm$user$access2$feat2.projects) !== null && _vm$user$access2$feat3 !== void 0 && _vm$user$access2$feat3.enabled) ? _c('v-row', {
    staticClass: "required",
    staticStyle: {
      "width": "408px"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/objects.svg"),
      "alt": ""
    }
  }), _c('h6', {
    staticClass: "content-bold"
  }, [_vm._v(_vm._s(_vm.$t("AddObject")))]), _c('v-row', {
    staticClass: "autocomplete-frame-address",
    staticStyle: {
      "width": "100%",
      "align-items": "center",
      "z-index": "1"
    }
  }, [_c('div', {
    staticClass: "object-address-container"
  }, [_c('div', {
    staticClass: "address-div",
    class: {
      'address-div': _vm.isErrorAddress === false,
      'address-div gray-border': _vm.isErrorAddress === true
    },
    attrs: {
      "id": "InAadressDiv"
    },
    on: {
      "click": _vm.focusInput,
      "focusout": _vm.unfocusInput,
      "keypress": function ($event) {
        _vm.objectSelectionOpen = false;
      }
    }
  })])])], 1) : _vm._e()], 1) : _vm._e(), _vm.user.config && !((_vm$user$access3 = _vm.user.access) !== null && _vm$user$access3 !== void 0 && (_vm$user$access3$feat = _vm$user$access3.features) !== null && _vm$user$access3$feat !== void 0 && (_vm$user$access3$feat2 = _vm$user$access3$feat.modules) !== null && _vm$user$access3$feat2 !== void 0 && (_vm$user$access3$feat3 = _vm$user$access3$feat2.projects) !== null && _vm$user$access3$feat3 !== void 0 && _vm$user$access3$feat3.enabled) && _vm.addressSelected ? _c('v-row', {
    staticClass: "subheading required"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ListingType")))])]) : _vm._e(), _vm.addressSelected || _vm.edit ? _c('v-row', {
    staticClass: "box-wrapper"
  }, _vm._l(_vm.dealTypes, function (dealType) {
    return _c('button', {
      key: dealType.name,
      staticClass: "big-box1 icon-box",
      class: {
        'big-box1 icon-box': !dealType.isChosen,
        'big-box1-white icon-box active-box': dealType.isChosen
      },
      attrs: {
        "item-text": "name",
        "item-value": "value"
      },
      on: {
        "click": function ($event) {
          _vm.form.dealType = dealType.value;
          _vm.chooseBox1(dealType.value);
        }
      }
    }, [_c('v-row', {
      staticStyle: {
        "align-items": "center"
      }
    }, [_c('img', {
      staticClass: "big-box3-image",
      class: {
        'big-box2-image': !dealType.isChosen,
        'big-box2-image-white ': dealType.isChosen
      },
      attrs: {
        "src": require('../../assets/images/' + dealType.name + '.svg'),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(dealType.name) + " ")])], 1);
  }), 0) : _vm._e(), _vm.edit || _vm.chosenLinkedDeal || _vm.addressSelected ? _c('div', [_c('v-row', {
    staticClass: "subheading required"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("EstateType")))])]), _c('v-row', {
    staticClass: "box-wrapper"
  }, _vm._l(_vm.estateTypes, function (estateType) {
    return _c('button', {
      key: estateType.name,
      staticClass: "big-box2 icon-box",
      class: {
        'big-box2 icon-box': !estateType.isChosen,
        'big-box2-white icon-box active-box': estateType.isChosen
      },
      attrs: {
        "name": 'estate-type-' + estateType.value,
        "item-text": "name",
        "item-value": "value"
      },
      on: {
        "click": function ($event) {
          _vm.form.estateType = estateType.value;
          _vm.chooseBox2(estateType.value);
        }
      }
    }, [_c('v-row', {
      staticStyle: {
        "align-items": "center"
      }
    }, [_c('img', {
      staticClass: "big-box2-image",
      class: {
        'big-box2-image': !estateType.isChosen,
        'big-box2-image-white': estateType.isChosen
      },
      attrs: {
        "src": require('../../assets/images/' + estateType.value + '.svg'),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(estateType.name) + " ")])], 1);
  }), 0), _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Address")))])]), _c('v-row', {
    staticClass: "address-selected"
  }, [_vm.form.street ? _c('p', [_vm._v(_vm._s(_vm.form.street))]) : _vm._e(), _vm.form.houseNumber ? _c('p', [_vm._v(" ")]) : _vm._e(), _vm.form.houseNumber ? _c('p', [_vm._v(_vm._s(_vm.form.houseNumber))]) : _vm._e(), _vm.form.apartmentNumber ? _c('p', [_vm._v("-" + _vm._s(_vm.form.apartmentNumber))]) : _vm._e(), _vm.form.houseNumber || _vm.form.street ? _c('p', [_vm._v(", ")]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.form.cityPart))]), _vm.form.cityPart ? _c('p', [_vm._v(", ")]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.form.district))]), _vm.form.district ? _c('p', [_vm._v(", ")]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.form.city))]), _vm.form.county ? _c('p', [_vm._v(", ")]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.form.county))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "justify-content": "space-between",
      "width": "100%",
      "gap": "12px"
    }
  }, [_c('v-col', {
    staticClass: "house-numbers",
    staticStyle: {
      "max-width": "50%"
    }
  }, [_vm.form.addressType !== 'generic' ? _c('v-row', [_vm.counties ? _c('Dropdown', {
    key: _vm.countiesUpdater,
    attrs: {
      "placeholder-text": _vm.$t('County'),
      "custom-style": 'width: 100%;height: 56px;z-index: 3;',
      "regular-size": true,
      "disabled": _vm.countiesDisabled,
      "items": _vm.counties,
      "search-box": true,
      "display-key": 'name',
      "value-key": 'name',
      "selected": _vm.selectedCounty
    },
    on: {
      "itemSelected": _vm.selectCounty
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.form.addressType !== 'generic' ? _c('v-row', [_c('Dropdown', {
    key: _vm.citiesUpdater,
    attrs: {
      "custom-style": 'width: 100%;font-size: 14px; line-height: 20px; height: 56px;z-index: 2;',
      "placeholder-text": _vm.$t('City'),
      "regular-size": true,
      "disabled": _vm.citiesDisabled,
      "search-box": true,
      "items": _vm.cities,
      "display-key": 'name',
      "value-key": 'name',
      "selected": _vm.selectedCity
    },
    on: {
      "itemSelected": _vm.selectCity
    }
  })], 1) : _vm._e(), _vm.form.addressType !== 'generic' ? _c('v-row', [_c('Dropdown', {
    key: _vm.districtsUpdater + 0.2,
    attrs: {
      "custom-style": 'width: 100%;height: 56px;',
      "placeholder-text": _vm.$t('District'),
      "regular-size": true,
      "disabled": _vm.districtsDisabled,
      "search-box": true,
      "items": _vm.districts,
      "display-key": 'name',
      "value-key": 'name',
      "selected": _vm.selectedDistrict
    },
    on: {
      "itemSelected": _vm.selectDistrict
    }
  })], 1) : _vm._e(), _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "street",
      "placeholder": _vm.$t('Street')
    },
    model: {
      value: _vm.form.street,
      callback: function ($$v) {
        _vm.$set(_vm.form, "street", $$v);
      },
      expression: "form.street"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "gap": "24px",
      "align-items": "center",
      "flex-wrap": "nowrap"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": "house-number",
      "placeholder": _vm.$t('HouseNumber')
    },
    model: {
      value: _vm.form.houseNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "houseNumber", $$v);
      },
      expression: "form.houseNumber"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('Show'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.showHouseNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "showHouseNumber", $$v);
      },
      expression: "form.showHouseNumber"
    }
  })], 1), _vm.form.estateType == 'apartment' || _vm.form.estateType == 'house_part' ? _c('v-row', {
    staticStyle: {
      "gap": "24px",
      "align-items": "center",
      "flex-wrap": "nowrap"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": "apartment-number",
      "placeholder": _vm.$t('ApartmentNumber')
    },
    model: {
      value: _vm.form.apartmentNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "apartmentNumber", $$v);
      },
      expression: "form.apartmentNumber"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('Show'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.showApartmentNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "showApartmentNumber", $$v);
      },
      expression: "form.showApartmentNumber"
    }
  })], 1) : _vm._e(), _c('v-row', {
    staticStyle: {
      "gap": "24px",
      "align-items": "center",
      "flex-wrap": "nowrap"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": "cadastral-number",
      "placeholder": _vm.$t('CadastralNumber')
    },
    model: {
      value: _vm.form.cadastralNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cadastralNumber", $$v);
      },
      expression: "form.cadastralNumber"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('Show'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.showCadastralNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "showCadastralNumber", $$v);
      },
      expression: "form.showCadastralNumber"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-left": "2px",
      "padding": "8px 0 8px 0"
    }
  }, [_c('span', {
    staticClass: "link-small",
    on: {
      "click": function ($event) {
        return _vm.openCadastalLink(_vm.form.cadastralNumber);
      }
    }
  }, [_c('img', {
    staticClass: "link-icon",
    attrs: {
      "src": require("../../assets/images/link.svg")
    }
  }), _c('span', {
    staticStyle: {
      "margin-left": "10px",
      "vertical-align": "middle"
    }
  }, [_vm._v(_vm._s(_vm.$t("OpenLandRegistry")))])])]), _c('v-row', {
    staticStyle: {
      "gap": "24px",
      "align-items": "center",
      "flex-wrap": "nowrap"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": "estate-number",
      "placeholder": _vm.$t('EstateNumber'),
      "value-changed": _vm.valueChanged
    },
    on: {
      "change": function ($event) {
        return _vm.numberInputFormatWholeNumber('estateNumber');
      }
    },
    model: {
      value: _vm.form.estateNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "estateNumber", $$v);
      },
      expression: "form.estateNumber"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('Show'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.showEstateNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "showEstateNumber", $$v);
      },
      expression: "form.showEstateNumber"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-left": "2px",
      "padding": "8px 0 8px 0"
    }
  }, [_c('span', {
    staticClass: "link-small",
    on: {
      "click": function ($event) {
        return _vm.openELandRegisterLink(_vm.form.cadastralNumber, _vm.form.estateNumber);
      }
    }
  }, [_c('img', {
    staticClass: "link-icon",
    attrs: {
      "src": require("../../assets/images/link.svg")
    }
  }), _c('span', {
    staticStyle: {
      "margin-left": "10px",
      "vertical-align": "middle"
    }
  }, [_vm._v(_vm._s(_vm.$t("OpenELandRegister")))])])]), _c('v-row', {
    staticStyle: {
      "gap": "24px",
      "align-items": "center",
      "flex-wrap": "nowrap"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": "ehr-number",
      "placeholder": _vm.$t('EhrNumber'),
      "value-changed": _vm.valueChanged
    },
    on: {
      "change": function ($event) {
        return _vm.numberInputFormatWholeNumber('ehrNumber');
      }
    },
    model: {
      value: _vm.form.ehrNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ehrNumber", $$v);
      },
      expression: "form.ehrNumber"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-left": "2px",
      "padding": "8px 0 8px 0"
    }
  }, [_c('span', {
    staticClass: "link-small",
    on: {
      "click": function ($event) {
        return _vm.openEhrNumberLink(_vm.form.ehrNumber);
      }
    }
  }, [_c('img', {
    staticClass: "link-icon",
    attrs: {
      "src": require("../../assets/images/link.svg")
    }
  }), _c('span', {
    staticStyle: {
      "margin-left": "10px",
      "vertical-align": "middle"
    }
  }, [_vm._v(_vm._s(_vm.$t("Visit")) + " EHR")])])])], 1), _c('v-col', [_c('div', {
    attrs: {
      "id": "map"
    }
  }, [_c('GmapMap', {
    staticStyle: {
      "width": "432px",
      "height": "240px"
    },
    attrs: {
      "center": _vm.mapCoordinates,
      "zoom": 18,
      "map-type-id": "terrain"
    },
    on: {
      "click": _vm.setMarker
    }
  }, [_c('GmapMarker', {
    attrs: {
      "id": "marker",
      "position": _vm.markerCoordinates,
      "clickable": true,
      "draggable": true
    },
    on: {
      "drag": _vm.updateMarker
    }
  })], 1)], 1)])], 1)], 1) : _vm._e()], 1), _vm.edit || _vm.chosenLinkedDeal || _vm.addressSelected ? _c('div', [_c('div', {
    staticClass: "section"
  }, [_c('v-row', [_c('div', {
    staticClass: "circle-number"
  }, [_vm._v("2")]), _c('h5', [_vm._v(_vm._s(_vm.$t("Price")) + " & " + _vm._s(_vm.$t("Availability")))])]), _c('v-row', {
    staticStyle: {
      "display": "flex",
      "gap": "12px",
      "margin-top": "32px"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "width": "210px"
    },
    attrs: {
      "id": "price",
      "format": 'price',
      "placeholder": _vm.$t('Price'),
      "required": true,
      "error": _vm.errors,
      "icon-right": "Price.svg",
      "value-changed": _vm.valueChanged
    },
    on: {
      "click": function ($event) {
        _vm.activePriceCalculatingInput = 'price';
      },
      "change": _vm.priceCalculate
    },
    model: {
      value: _vm.form.price,
      callback: function ($$v) {
        _vm.$set(_vm.form, "price", $$v);
      },
      expression: "form.price"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "width": "210px"
    },
    attrs: {
      "id": "square-meter-price",
      "placeholder": _vm.$t('PricePerUnit'),
      "icon-right": "eur+m2.svg",
      "value-changed": _vm.valueChanged
    },
    on: {
      "click": function ($event) {
        _vm.activePriceCalculatingInput = 'pricePerSquareMeter';
      },
      "change": _vm.priceCalculate
    },
    model: {
      value: _vm.form.pricePerSquareMeter,
      callback: function ($$v) {
        _vm.$set(_vm.form, "pricePerSquareMeter", $$v);
      },
      expression: "form.pricePerSquareMeter"
    }
  })], 1), _vm.form.dealType !== 'sale' ? _c('v-row', [_c('v-checkbox', {
    staticClass: "chechkbox-parameters",
    staticStyle: {
      "padding-top": "18px",
      "padding-left": "12px"
    },
    attrs: {
      "label": _vm.$t('RentToOwn'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.rentToOwn,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rentToOwn", $$v);
      },
      expression: "form.rentToOwn"
    }
  })], 1) : _vm._e(), _vm.form.dealType !== 'short_term_rent' ? _c('v-row', [_vm.form.dealType !== 'short_term_rent' ? _c('v-checkbox', {
    staticClass: "chechkbox-parameters",
    staticStyle: {
      "padding-left": "12px",
      "padding-top": "16px"
    },
    attrs: {
      "label": _vm.$t('Tradable'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.additionalInfo.tradable,
      callback: function ($$v) {
        _vm.$set(_vm.form.additionalInfo, "tradable", $$v);
      },
      expression: "form.additionalInfo.tradable"
    }
  }) : _vm._e()], 1) : _vm._e(), _c('v-row', [_c('v-checkbox', {
    staticStyle: {
      "padding-left": "12px",
      "padding-top": "16px",
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('ImmediatelyAvailable'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.availableImmediately,
      callback: function ($$v) {
        _vm.$set(_vm.form, "availableImmediately", $$v);
      },
      expression: "form.availableImmediately"
    }
  })], 1), _vm.form.availableImmediately ? _c('v-row', {
    staticStyle: {
      "width": "210px"
    }
  }, [_c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "placeholder": _vm.$t('DateAvailable'),
      "disabled": true,
      "type": "date"
    }
  })], 1) : _vm._e(), !_vm.form.availableImmediately ? _c('v-row', {
    staticStyle: {
      "width": "210px"
    }
  }, [_c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "placeholder": _vm.$t('DateAvailable'),
      "type": "date"
    },
    model: {
      value: _vm.form.dateAvailable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "dateAvailable", $$v);
      },
      expression: "form.dateAvailable"
    }
  })], 1) : _vm._e()], 1), _c('div', {
    staticClass: "section"
  }, [_c('v-row', [_c('div', {
    staticClass: "circle-number"
  }, [_vm._v("3")]), _c('h5', [_vm._v(_vm._s(_vm.$t("General")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "32px",
      "gap": "32px"
    }
  }, [_c('v-col', {
    staticClass: "first-general-column",
    staticStyle: {
      "min-width": "432px",
      "max-width": "432px"
    }
  }, [_c('v-row', {
    staticClass: "floor-items"
  }, [_vm.form.estateType !== 'land' ? _c('BaseInput', {
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "id": "floors",
      "type": "number",
      "placeholder": _vm.$t('FloorCount')
    },
    model: {
      value: _vm.form.floors,
      callback: function ($$v) {
        _vm.$set(_vm.form, "floors", $$v);
      },
      expression: "form.floors"
    }
  }) : _vm._e(), _vm.form.estateType === 'garage' || _vm.form.estateType === 'apartment' || _vm.form.estateType === 'commercial' || _vm.form.estateType === 'house_part' ? _c('BaseInput', {
    staticStyle: {
      "width": "120px"
    },
    attrs: {
      "id": "floor",
      "type": "number",
      "placeholder": _vm.$t('Floor')
    },
    model: {
      value: _vm.form.floor,
      callback: function ($$v) {
        _vm.$set(_vm.form, "floor", $$v);
      },
      expression: "form.floor"
    }
  }) : _vm._e()], 1), _vm.form.estateType !== 'land' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "width": "432px"
    },
    attrs: {
      "id": "build-year",
      "type": "number",
      "placeholder": _vm.$t('BuildYear')
    },
    model: {
      value: _vm.form.buildYear,
      callback: function ($$v) {
        _vm.$set(_vm.form, "buildYear", $$v);
      },
      expression: "form.buildYear"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'land' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "width": "432px"
    },
    attrs: {
      "id": "general-surfaceArea",
      "placeholder": _vm.$t('GeneralSurfaceArea'),
      "icon-right": "SquareMeter.svg",
      "value-changed": _vm.valueChanged
    },
    on: {
      "click": function ($event) {
        _vm.activePriceCalculatingInput = 'surfaceArea';
      },
      "change": _vm.priceCalculate
    },
    model: {
      value: _vm.form.generalSurfaceArea,
      callback: function ($$v) {
        _vm.$set(_vm.form, "generalSurfaceArea", $$v);
      },
      expression: "form.generalSurfaceArea"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'apartment' ? _c('v-row', {
    staticStyle: {
      "gap": "12px",
      "align-items": "center",
      "flex-wrap": "nowrap"
    }
  }, [_vm.landAreaUnit === 'm2' ? _c('BaseInput', {
    staticStyle: {
      "width": "210px"
    },
    attrs: {
      "id": "land-area-m2",
      "placeholder": _vm.$t('LandArea'),
      "value-changed": _vm.valueChanged
    },
    on: {
      "click": function ($event) {
        _vm.activePriceCalculatingInput = 'm2';
      },
      "change": _vm.priceCalculate
    },
    model: {
      value: _vm.form.landArea.m2,
      callback: function ($$v) {
        _vm.$set(_vm.form.landArea, "m2", $$v);
      },
      expression: "form.landArea.m2"
    }
  }) : _vm._e(), _vm.landAreaUnit === 'ha' ? _c('BaseInput', {
    staticStyle: {
      "width": "210px"
    },
    attrs: {
      "id": "land-area-ha",
      "placeholder": _vm.$t('LandArea'),
      "value-changed": _vm.valueChanged
    },
    on: {
      "click": function ($event) {
        _vm.activePriceCalculatingInput = 'ha';
      },
      "change": _vm.priceCalculate
    },
    model: {
      value: _vm.form.landArea.ha,
      callback: function ($$v) {
        _vm.$set(_vm.form.landArea, "ha", $$v);
      },
      expression: "form.landArea.ha"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "input-wrapper",
    staticStyle: {
      "max-height": "fit-content"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.landAreaUnit,
      callback: function ($$v) {
        _vm.landAreaUnit = $$v;
      },
      expression: "landAreaUnit"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": 'm2',
      "color": "orange darken-3",
      "value": 'm2'
    }
  }), _c('v-radio', {
    attrs: {
      "label": 'ha',
      "color": "orange darken-3",
      "value": 'ha'
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.form.estateType !== 'apartment' && _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'land' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "width": "432px"
    },
    attrs: {
      "id": "living-area",
      "type": "number",
      "placeholder": _vm.$t('LivingArea'),
      "icon-right": "SquareMeter.svg"
    },
    model: {
      value: _vm.form.livingArea,
      callback: function ($$v) {
        _vm.$set(_vm.form, "livingArea", $$v);
      },
      expression: "form.livingArea"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'garage' && _vm.form.estateType !== 'land' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "width": "432px"
    },
    attrs: {
      "id": "rooms",
      "type": "number",
      "placeholder": _vm.$t('RoomCount')
    },
    model: {
      value: _vm.form.rooms,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rooms", $$v);
      },
      expression: "form.rooms"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'garage' && _vm.form.estateType !== 'land' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "width": "432px"
    },
    attrs: {
      "id": "bedrooms",
      "type": "number",
      "placeholder": _vm.$t('BedroomCount')
    },
    model: {
      value: _vm.form.bedrooms,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bedrooms", $$v);
      },
      expression: "form.bedrooms"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'garage' && _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'land' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "width": "432px"
    },
    attrs: {
      "id": "bathroom-Count",
      "type": "number",
      "placeholder": _vm.$t('BathroomCount')
    },
    model: {
      value: _vm.form.bathroomCount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bathroomCount", $$v);
      },
      expression: "form.bathroomCount"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'apartment' ? _c('v-row', [_c('v-checkbox', {
    attrs: {
      "id": "rooms-separate",
      "label": _vm.$t('RoomsSeparate'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.roomsSeparate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "roomsSeparate", $$v);
      },
      expression: "form.roomsSeparate"
    }
  })], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "second-general-column",
    staticStyle: {
      "min-width": "432px",
      "max-width": "432px"
    }
  }, [_vm.form.estateType != 'land' ? _c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "condition-select",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.conditions,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('Condition')
    },
    model: {
      value: _vm.form.condition,
      callback: function ($$v) {
        _vm.$set(_vm.form, "condition", $$v);
      },
      expression: "form.condition"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'apartment' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "clearable": "",
      "items": _vm.buildingTypes,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('BuildingType')
    },
    model: {
      value: _vm.form.buildingType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "buildingType", $$v);
      },
      expression: "form.buildingType"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'apartment' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "clearable": "",
      "items": _vm.readinesses,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('Readiness')
    },
    model: {
      value: _vm.form.readiness,
      callback: function ($$v) {
        _vm.$set(_vm.form, "readiness", $$v);
      },
      expression: "form.readiness"
    }
  }) : _vm._e(), _c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "clearable": "",
      "outlined": "",
      "items": _vm.ownershipForms,
      "item-text": "name",
      "item-value": "value",
      "label": _vm.$t('FormOfOwnership')
    },
    model: {
      value: _vm.form.ownershipForm,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ownershipForm", $$v);
      },
      expression: "form.ownershipForm"
    }
  }), _vm.form.estateType != 'land' ? _c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "clearable": "",
      "outlined": "",
      "items": _vm.buildingMaterials,
      "item-text": "name",
      "item-value": "value",
      "label": _vm.$t('BuildingMaterial')
    },
    model: {
      value: _vm.form.buildingMaterial,
      callback: function ($$v) {
        _vm.$set(_vm.form, "buildingMaterial", $$v);
      },
      expression: "form.buildingMaterial"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' ? _c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "clearable": "",
      "items": _vm.energyLabels,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('EnergyLabel')
    },
    model: {
      value: _vm.form.energyLabel,
      callback: function ($$v) {
        _vm.$set(_vm.form, "energyLabel", $$v);
      },
      expression: "form.energyLabel"
    }
  }) : _vm._e(), _c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "clearable": "",
      "items": _vm.limitations,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('Limitations')
    },
    model: {
      value: _vm.form.limitations,
      callback: function ($$v) {
        _vm.$set(_vm.form, "limitations", $$v);
      },
      expression: "form.limitations"
    }
  }), _vm.form.estateType !== 'land' && _vm.form.estateType !== 'garage' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "expenses-in-summer",
      "type": "number",
      "placeholder": _vm.$t('ExpensesInSummer'),
      "icon-right": "Price.svg"
    },
    on: {
      "change": _vm.calculateExpenses
    },
    model: {
      value: _vm.form.expensesInSummer,
      callback: function ($$v) {
        _vm.$set(_vm.form, "expensesInSummer", $$v);
      },
      expression: "form.expensesInSummer"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'land' && _vm.form.estateType !== 'garage' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "expenses-in-winter",
      "type": "number",
      "placeholder": _vm.$t('ExpensesInWinter'),
      "icon-right": "Price.svg"
    },
    on: {
      "change": _vm.calculateExpenses
    },
    model: {
      value: _vm.form.expensesInWinter,
      callback: function ($$v) {
        _vm.$set(_vm.form, "expensesInWinter", $$v);
      },
      expression: "form.expensesInWinter"
    }
  })], 1) : _vm._e(), _vm.form.estateType == 'land' ? _c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "clearable": "",
      "items": _vm.detailedPlans,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('DetailPlan')
    },
    model: {
      value: _vm.form.detailedPlan,
      callback: function ($$v) {
        _vm.$set(_vm.form, "detailedPlan", $$v);
      },
      expression: "form.detailedPlan"
    }
  }) : _vm._e()], 1)], 1), _vm.form.estateType == 'land' ? _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("LandPurposeOfUse")))])]) : _vm._e(), _vm.form.estateType == 'land' ? _c('v-row', {
    staticStyle: {
      "gap": "32px"
    }
  }, [_c('v-col', {
    staticClass: "first-general-column",
    staticStyle: {
      "min-width": "432px",
      "max-width": "432px"
    }
  }, [_vm.form.estateType == 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px",
      "padding-top": "20px"
    },
    attrs: {
      "label": _vm.$t('CommercialLand'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.commercialLand,
      callback: function ($$v) {
        _vm.$set(_vm.form, "commercialLand", $$v);
      },
      expression: "form.commercialLand"
    }
  }) : _vm._e(), _vm.form.estateType == 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('ProductionLand'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.productionLand,
      callback: function ($$v) {
        _vm.$set(_vm.form, "productionLand", $$v);
      },
      expression: "form.productionLand"
    }
  }) : _vm._e(), _vm.form.estateType == 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('IndustrialLand'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.industrialLand,
      callback: function ($$v) {
        _vm.$set(_vm.form, "industrialLand", $$v);
      },
      expression: "form.industrialLand"
    }
  }) : _vm._e(), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('ForestLand'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.forestLand,
      callback: function ($$v) {
        _vm.$set(_vm.form, "forestLand", $$v);
      },
      expression: "form.forestLand"
    }
  })], 1), _c('v-col', {
    staticClass: "second-general-column",
    staticStyle: {
      "min-width": "432px",
      "max-width": "432px"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('ResidentialLand'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.residentialLand,
      callback: function ($$v) {
        _vm.$set(_vm.form, "residentialLand", $$v);
      },
      expression: "form.residentialLand"
    }
  }), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px",
      "padding-top": "20px"
    },
    attrs: {
      "label": _vm.$t('ProfitYieldingLand'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.profityieldingLand,
      callback: function ($$v) {
        _vm.$set(_vm.form, "profityieldingLand", $$v);
      },
      expression: "form.profityieldingLand"
    }
  }), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('ResidentialLandApartments'),
      "value": "",
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.residentialLandApartment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "residentialLandApartment", $$v);
      },
      expression: "form.residentialLandApartment"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('OtherUse'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.otherUse,
      callback: function ($$v) {
        _vm.$set(_vm.form, "otherUse", $$v);
      },
      expression: "form.otherUse"
    }
  })], 1)], 1) : _vm._e(), _vm.form.estateType == 'commercial' ? _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("CommercialPurposeOfUse")))])]) : _vm._e(), _vm.form.estateType == 'commercial' ? _c('v-row', {
    staticStyle: {
      "gap": "32px"
    }
  }, [_c('v-col', {
    staticClass: "first-general-column",
    staticStyle: {
      "min-width": "432px",
      "max-width": "432px"
    }
  }, [_c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px",
      "padding-top": "20px"
    },
    attrs: {
      "label": _vm.$t('Office'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.office,
      callback: function ($$v) {
        _vm.$set(_vm.form, "office", $$v);
      },
      expression: "form.office"
    }
  }), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('TradeShop'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.tradeshop,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tradeshop", $$v);
      },
      expression: "form.tradeshop"
    }
  }), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('Accomodation'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.accomodation,
      callback: function ($$v) {
        _vm.$set(_vm.form, "accomodation", $$v);
      },
      expression: "form.accomodation"
    }
  }), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('Services'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.services,
      callback: function ($$v) {
        _vm.$set(_vm.form, "services", $$v);
      },
      expression: "form.services"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('WareHouse'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.warehouse,
      callback: function ($$v) {
        _vm.$set(_vm.form, "warehouse", $$v);
      },
      expression: "form.warehouse"
    }
  })], 1), _c('v-col', {
    staticClass: "second-general-column",
    staticStyle: {
      "min-width": "432px",
      "max-width": "432px"
    }
  }, [_c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px",
      "padding-top": "20px"
    },
    attrs: {
      "label": _vm.$t('Production'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.production,
      callback: function ($$v) {
        _vm.$set(_vm.form, "production", $$v);
      },
      expression: "form.production"
    }
  }), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('FoodServices'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.foodServices,
      callback: function ($$v) {
        _vm.$set(_vm.form, "foodServices", $$v);
      },
      expression: "form.foodServices"
    }
  }), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('NotDefined'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.notDefined,
      callback: function ($$v) {
        _vm.$set(_vm.form, "notDefined", $$v);
      },
      expression: "form.notDefined"
    }
  }), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('WareHouseProduction'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.warehouseProduction,
      callback: function ($$v) {
        _vm.$set(_vm.form, "warehouseProduction", $$v);
      },
      expression: "form.warehouseProduction"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('OtherUsePossible'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.otherUsePossible,
      callback: function ($$v) {
        _vm.$set(_vm.form, "otherUsePossible", $$v);
      },
      expression: "form.otherUsePossible"
    }
  })], 1)], 1) : _vm._e()], 1), _c('div', {
    staticClass: "section"
  }, [_c('v-row', [_c('div', {
    staticClass: "circle-number"
  }, [_vm._v("4")]), _c('h5', [_vm._v(_vm._s(_vm.$t("AdditionalInfo")))])]), _c('v-row', [_c('v-row', {
    staticClass: "box-wrapper",
    staticStyle: {
      "padding-top": "20px"
    }
  }, _vm._l(_vm.sectionTypes, function (sectionType) {
    return _c('button', {
      key: sectionType.name,
      staticClass: "big-box2 icon-box",
      class: {
        'big-box2 icon-box': !sectionType.isChosen,
        'big-box2-white icon-box active-box': sectionType.isChosen
      },
      attrs: {
        "id": sectionType.value,
        "item-text": "name",
        "item-value": "value"
      },
      on: {
        "click": function ($event) {
          _vm.form.sectionType = sectionType.value;
          _vm.chooseSection2(sectionType.value);
        }
      }
    }, [_c('v-row', {
      staticStyle: {
        "align-items": "center"
      }
    }, [_c('img', {
      staticClass: "big-box2-image2",
      class: {
        'big-box2-image': !sectionType.isChosen,
        'big-box2-image-white': sectionType.isChosen
      },
      attrs: {
        "src": require('../../assets/images/' + sectionType.name + '.svg'),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(sectionType.name) + " ")])], 1);
  }), 0), _vm.form.sectionType != 'sanitary' && _vm.form.sectionType != 'surroundings' && _vm.form.sectionType != 'communication' && _vm.form.sectionType != 'heating' && _vm.form.sectionType != 'kitchen' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-col', {
    staticClass: "additional-first-row",
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType != 'commercial' && _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    attrs: {
      "id": "balcony-exists",
      "label": _vm.$t('Balcony'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.balcony,
      callback: function ($$v) {
        _vm.$set(_vm.form, "balcony", $$v);
      },
      expression: "form.balcony"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "balcony-is-closed",
      "label": _vm.$t('ClosedBalcony'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.closedBalcony,
      callback: function ($$v) {
        _vm.$set(_vm.form, "closedBalcony", $$v);
      },
      expression: "form.closedBalcony"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "balcony-is-fully-glazed",
      "label": _vm.$t('FullyGlazedBalcony'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.fullyGlazedBalcony,
      callback: function ($$v) {
        _vm.$set(_vm.form, "fullyGlazedBalcony", $$v);
      },
      expression: "form.fullyGlazedBalcony"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "balcony-is-french",
      "label": _vm.$t('FrenchBalcony'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.frenchBalcony,
      callback: function ($$v) {
        _vm.$set(_vm.form, "frenchBalcony", $$v);
      },
      expression: "form.frenchBalcony"
    }
  }) : _vm._e(), _c('v-row', {
    staticStyle: {
      "margin-top": "0"
    }
  }), _vm.form.estateType !== 'garage' && _vm.form.estateType !== 'land' ? _c('v-row', {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": "balcony-size",
      "type": "number",
      "placeholder": _vm.$t('Balcony'),
      "icon-right": "SquareMeter.svg"
    },
    model: {
      value: _vm.form.balconySize,
      callback: function ($$v) {
        _vm.$set(_vm.form, "balconySize", $$v);
      },
      expression: "form.balconySize"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'garage' && _vm.form.estateType !== 'land' && _vm.form.estateType !== 'commercial' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "kitchen-size",
      "placeholder": _vm.$t('Kitchen'),
      "icon-right": "SquareMeter.svg",
      "type": "number"
    },
    on: {
      "change": _vm.calculateKitchenSize
    },
    model: {
      value: _vm.form.kitchenSize,
      callback: function ($$v) {
        _vm.$set(_vm.form, "kitchenSize", $$v);
      },
      expression: "form.kitchenSize"
    }
  })], 1) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-select', {
    staticStyle: {
      "margin-bottom": "-18px",
      "padding-top": "0px"
    },
    attrs: {
      "clearable": "",
      "items": _vm.parkingOptions,
      "outlined": "",
      "item-text": "name",
      "item-value": "value",
      "label": _vm.$t('ParkingOption')
    },
    model: {
      value: _vm.form.parkingOption,
      callback: function ($$v) {
        _vm.$set(_vm.form, "parkingOption", $$v);
      },
      expression: "form.parkingOption"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "8px"
    },
    attrs: {
      "id": "has-garage",
      "label": _vm.$t('Garage'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.garage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "garage", $$v);
      },
      expression: "form.garage"
    }
  }) : _vm._e(), _vm.form.estateType === 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "garage-is-underground",
      "label": _vm.$t('UndergroundGarage'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.undergroundGarage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "undergroundGarage", $$v);
      },
      expression: "form.undergroundGarage"
    }
  }) : _vm._e(), _c('v-row', {
    staticStyle: {
      "margin-top": "0"
    }
  }), _vm.form.estateType === 'commercial' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "kitchen-size",
      "type": "number",
      "placeholder": _vm.$t('Kitchen'),
      "icon-right": "SquareMeter.svg"
    },
    model: {
      value: _vm.form.kitchenSize,
      callback: function ($$v) {
        _vm.$set(_vm.form, "kitchenSize", $$v);
      },
      expression: "form.kitchenSize"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'commercial' ? _c('v-row', {
    staticStyle: {
      "margin-bottom": "28px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": "number-of-desks",
      "placeholder": _vm.$t('NumberOfDesks'),
      "icon-right": "SquareMeter.svg"
    },
    model: {
      value: _vm.form.numberOfDesks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "numberOfDesks", $$v);
      },
      expression: "form.numberOfDesks"
    }
  })], 1) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' && _vm.form.estateType != 'apartment' && _vm.form.estateType != 'house' && _vm.form.estateType != 'house_part' && _vm.form.estateType != 'cottage' ? _c('v-select', {
    staticStyle: {
      "margin-top": "8px !important",
      "width": "432px"
    },
    attrs: {
      "clearable": "",
      "outlined": "",
      "items": _vm.roofTypes,
      "item-text": "name",
      "item-value": "value",
      "label": _vm.$t('RoofType')
    },
    model: {
      value: _vm.form.roofType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "roofType", $$v);
      },
      expression: "form.roofType"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' || _vm.form.estateType == 'house' || _vm.form.estateType == 'house_part' || _vm.form.estateType == 'cottage' ? _c('v-select', {
    staticStyle: {
      "margin-top": "24px !important",
      "width": "432px"
    },
    attrs: {
      "clearable": "",
      "outlined": "",
      "item-text": "name",
      "item-value": "value",
      "items": _vm.roofTypes,
      "label": _vm.$t('RoofType')
    },
    model: {
      value: _vm.form.roofType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "roofType", $$v);
      },
      expression: "form.roofType"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "0px",
      "margin-top": "-4px !important"
    },
    attrs: {
      "id": "has-elevator",
      "label": _vm.$t('Lift'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.lift,
      callback: function ($$v) {
        _vm.$set(_vm.form, "lift", $$v);
      },
      expression: "form.lift"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-parquet",
      "label": _vm.$t('Parquet'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.parquet,
      callback: function ($$v) {
        _vm.$set(_vm.form, "parquet", $$v);
      },
      expression: "form.parquet"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' && _vm.form.estateType != 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-packet-windows",
      "label": _vm.$t('PacketWindows'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.packetWindows,
      callback: function ($$v) {
        _vm.$set(_vm.form, "packetWindows", $$v);
      },
      expression: "form.packetWindows"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-furniture",
      "label": _vm.$t('Furniture'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.furniture,
      callback: function ($$v) {
        _vm.$set(_vm.form, "furniture", $$v);
      },
      expression: "form.furniture"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "is-furnishing-possible",
      "label": _vm.$t('FurnitureChangePossibility'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.furnitureAbility,
      callback: function ($$v) {
        _vm.$set(_vm.form, "furnitureAbility", $$v);
      },
      expression: "form.furnitureAbility"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "plan-through-multiple-floors",
      "label": _vm.$t('PlanThroughMultipleFloors'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.severalFloorsPlanning,
      callback: function ($$v) {
        _vm.$set(_vm.form, "severalFloorsPlanning", $$v);
      },
      expression: "form.severalFloorsPlanning"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-high-ceilings",
      "label": _vm.$t('HighCeilings'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.highCeilings,
      callback: function ($$v) {
        _vm.$set(_vm.form, "highCeilings", $$v);
      },
      expression: "form.highCeilings"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-new-wiring",
      "label": _vm.$t('NewWiring'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.electricalWiring,
      callback: function ($$v) {
        _vm.$set(_vm.form, "electricalWiring", $$v);
      },
      expression: "form.electricalWiring"
    }
  }) : _vm._e(), _vm.form.estateType != 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-electricity",
      "label": _vm.$t('Electricity'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.electricity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "electricity", $$v);
      },
      expression: "form.electricity"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' && _vm.form.estateType != 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-wardrobe",
      "label": _vm.$t('Wardrobe'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.wardrobe,
      callback: function ($$v) {
        _vm.$set(_vm.form, "wardrobe", $$v);
      },
      expression: "form.wardrobe"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "additional-second-row",
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    attrs: {
      "id": "has-attic",
      "label": _vm.$t('Attic'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.attic,
      callback: function ($$v) {
        _vm.$set(_vm.form, "attic", $$v);
      },
      expression: "form.attic"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' && _vm.form.estateType != 'house' && _vm.form.estateType != 'house_part' && _vm.form.estateType != 'cottage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-storeroom",
      "label": _vm.$t('Storeroom'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.storeRoom,
      callback: function ($$v) {
        _vm.$set(_vm.form, "storeRoom", $$v);
      },
      expression: "form.storeRoom"
    }
  }) : _vm._e(), _vm.form.estateType == 'house' || _vm.form.estateType == 'house_part' || _vm.form.estateType == 'cottage' ? _c('v-checkbox', {
    attrs: {
      "id": "has-storeroom",
      "label": _vm.$t('Storeroom'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.storeRoom,
      callback: function ($$v) {
        _vm.$set(_vm.form, "storeRoom", $$v);
      },
      expression: "form.storeRoom"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-terrace",
      "label": _vm.$t('Terrace'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.terrace,
      callback: function ($$v) {
        _vm.$set(_vm.form, "terrace", $$v);
      },
      expression: "form.terrace"
    }
  }) : _vm._e(), _vm.form.estateType == 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-showcase-windows",
      "label": _vm.$t('ShowCaseWindows'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.showCaseWindows,
      callback: function ($$v) {
        _vm.$set(_vm.form, "showCaseWindows", $$v);
      },
      expression: "form.showCaseWindows"
    }
  }) : _vm._e(), _vm.form.estateType == 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-street-entrance",
      "label": _vm.$t('StreetEntrance'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.streetEntrance,
      callback: function ($$v) {
        _vm.$set(_vm.form, "streetEntrance", $$v);
      },
      expression: "form.streetEntrance"
    }
  }) : _vm._e(), _vm.form.estateType == 'commercial' ? _c('v-checkbox', {
    staticClass: "trestle",
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-trestle",
      "label": _vm.$t('Trestle'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.trestle,
      callback: function ($$v) {
        _vm.$set(_vm.form, "trestle", $$v);
      },
      expression: "form.trestle"
    }
  }) : _vm._e(), _vm.form.estateType == 'commercial' ? _c('v-checkbox', {
    staticClass: "goodslift",
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-goods-lift",
      "label": _vm.$t('GoodsLift'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.goodsLift,
      callback: function ($$v) {
        _vm.$set(_vm.form, "goodsLift", $$v);
      },
      expression: "form.goodsLift"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' && _vm.form.estateType != 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-tv",
      "label": 'TV',
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.tv,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tv", $$v);
      },
      expression: "form.tv"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-basement",
      "label": _vm.$t('BasementFloor'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.basementFloor,
      callback: function ($$v) {
        _vm.$set(_vm.form, "basementFloor", $$v);
      },
      expression: "form.basementFloor"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "is-rooftop-apartment",
      "label": _vm.$t('RoofTopApartment'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.rooftopApartment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rooftopApartment", $$v);
      },
      expression: "form.rooftopApartment"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-separate-entrance",
      "label": _vm.$t('SeparateEntrance'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.separateEntrance,
      callback: function ($$v) {
        _vm.$set(_vm.form, "separateEntrance", $$v);
      },
      expression: "form.separateEntrance"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-closed-yard",
      "label": _vm.$t('ClosedYard'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.closedYard,
      callback: function ($$v) {
        _vm.$set(_vm.form, "closedYard", $$v);
      },
      expression: "form.closedYard"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' || _vm.form.estateType == 'commercial' ? _c('v-checkbox', {
    staticClass: "meetingRoom",
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-meeting-room",
      "label": _vm.$t('MeetingRoom'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.meetingRoom,
      callback: function ($$v) {
        _vm.$set(_vm.form, "meetingRoom", $$v);
      },
      expression: "form.meetingRoom"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-industrial-power-supply",
      "label": _vm.$t('IndustrialPowerSupply'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.industrialPowerSupply,
      callback: function ($$v) {
        _vm.$set(_vm.form, "industrialPowerSupply", $$v);
      },
      expression: "form.industrialPowerSupply"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-climate-device",
      "label": _vm.$t('ClimaticDevice'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.climaticDevice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "climaticDevice", $$v);
      },
      expression: "form.climaticDevice"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-ventilation",
      "label": _vm.$t('Ventilation'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.ventilation,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ventilation", $$v);
      },
      expression: "form.ventilation"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'apartment' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-well",
      "label": _vm.$t('Well'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.well,
      callback: function ($$v) {
        _vm.$set(_vm.form, "well", $$v);
      },
      expression: "form.well"
    }
  }) : _vm._e(), _vm.form.estateType == 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-sewerage",
      "label": _vm.$t('Sewerage'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.sewerage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sewerage", $$v);
      },
      expression: "form.sewerage"
    }
  }) : _vm._e(), _vm.form.estateType == 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "gas-included",
      "label": _vm.$t('GasIncluded'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.gasIncluded,
      callback: function ($$v) {
        _vm.$set(_vm.form, "gasIncluded", $$v);
      },
      expression: "form.gasIncluded"
    }
  }) : _vm._e(), _vm.form.estateType == 'land' || _vm.form.estateType == 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-water",
      "label": _vm.$t('Water'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.water,
      callback: function ($$v) {
        _vm.$set(_vm.form, "water", $$v);
      },
      expression: "form.water"
    }
  }) : _vm._e(), _vm.form.estateType == 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-road",
      "label": _vm.$t('Road'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.road,
      callback: function ($$v) {
        _vm.$set(_vm.form, "road", $$v);
      },
      expression: "form.road"
    }
  }) : _vm._e(), _vm.form.estateType != 'commercial' && _vm.form.estateType != 'apartment' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-public-transport",
      "label": _vm.$t('PublicTransport'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.publicTransport,
      callback: function ($$v) {
        _vm.$set(_vm.form, "publicTransport", $$v);
      },
      expression: "form.publicTransport"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.form.sectionType == 'sanitary' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-col', {
    staticClass: "additional-first-row",
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType != 'commercial' && _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    attrs: {
      "id": "has-washing-machine",
      "label": _vm.$t('WashingMachine'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.washingMachine,
      callback: function ($$v) {
        _vm.$set(_vm.form, "washingMachine", $$v);
      },
      expression: "form.washingMachine"
    }
  }) : _vm._e(), _vm.form.estateType != 'commercial' && _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-pool",
      "label": _vm.$t('Pool'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.pool,
      callback: function ($$v) {
        _vm.$set(_vm.form, "pool", $$v);
      },
      expression: "form.pool"
    }
  }) : _vm._e(), _vm.form.estateType == 'commercial' ? _c('v-checkbox', {
    attrs: {
      "id": "has-pool",
      "label": _vm.$t('Pool'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.pool,
      callback: function ($$v) {
        _vm.$set(_vm.form, "pool", $$v);
      },
      expression: "form.pool"
    }
  }) : _vm._e(), _vm.form.estateType != 'commercial' && _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-boiler",
      "label": _vm.$t('Boiler'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.boiler,
      callback: function ($$v) {
        _vm.$set(_vm.form, "boiler", $$v);
      },
      expression: "form.boiler"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-shower",
      "label": _vm.$t('Shower'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.shower,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shower", $$v);
      },
      expression: "form.shower"
    }
  }) : _vm._e(), _vm.form.estateType != 'commercial' && _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-local-sewerage",
      "label": _vm.$t('LocalSewerage'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.localSewerage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "localSewerage", $$v);
      },
      expression: "form.localSewerage"
    }
  }) : _vm._e(), _vm.form.estateType != 'commercial' && _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-central-sewerage",
      "label": _vm.$t('CentralSewerage'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.centralSewerage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "centralSewerage", $$v);
      },
      expression: "form.centralSewerage"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "additional-second-row",
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'apartment' ? _c('v-checkbox', {
    attrs: {
      "id": "has-local-water-supply",
      "label": _vm.$t('LocalWaterSupply'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.localWaterSupply,
      callback: function ($$v) {
        _vm.$set(_vm.form, "localWaterSupply", $$v);
      },
      expression: "form.localWaterSupply"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-central-water-supply",
      "label": _vm.$t('CentralWaterSupply'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.centralWaterSupply,
      callback: function ($$v) {
        _vm.$set(_vm.form, "centralWaterSupply", $$v);
      },
      expression: "form.centralWaterSupply"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    attrs: {
      "id": "has-new-plumbing",
      "label": _vm.$t('NewPlumbing'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.piping,
      callback: function ($$v) {
        _vm.$set(_vm.form, "piping", $$v);
      },
      expression: "form.piping"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-bath",
      "label": _vm.$t('Bath'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.bath,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bath", $$v);
      },
      expression: "form.bath"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-sauna",
      "label": _vm.$t('Sauna'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.sauna,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sauna", $$v);
      },
      expression: "form.sauna"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-separate-toilet-and-bath",
      "label": _vm.$t('SeparateToiletAndBath'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.wcBath,
      callback: function ($$v) {
        _vm.$set(_vm.form, "wcBath", $$v);
      },
      expression: "form.wcBath"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.form.sectionType == 'surroundings' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "gap": "32px"
    }
  }, [_c('v-col', {
    staticStyle: {
      "padding": "32px 0 0 0",
      "max-width": "432px"
    },
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType != 'garage' ? _c('v-select', {
    staticStyle: {
      "max-width": "432px",
      "margin-bottom": "-12px !important"
    },
    attrs: {
      "clearable": "",
      "items": _vm.reservois,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('BodyOfWater')
    },
    model: {
      value: _vm.form.reservoir,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reservoir", $$v);
      },
      expression: "form.reservoir"
    }
  }) : _vm._e(), _vm.form.estateType !== 'garage' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      "id": "reservoir-name",
      "placeholder": _vm.$t('BodyOfWaterName')
    },
    model: {
      value: _vm.form.reservoirName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reservoirName", $$v);
      },
      expression: "form.reservoirName"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'garage' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "margin-top": "0px"
    },
    attrs: {
      "id": "reservoir-distance",
      "type": "number",
      "placeholder": _vm.$t('BodyOfWaterDistance') + ' (m)'
    },
    model: {
      value: _vm.form.reservoirDistance,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reservoirDistance", $$v);
      },
      expression: "form.reservoirDistance"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'garage' && _vm.form.estateType !== 'apartment' ? _c('v-row', {
    staticStyle: {
      "margin-top": "12px",
      "margin-bottom": "12px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": "coastLine-length",
      "type": "number",
      "placeholder": _vm.$t('CoastLineLength')
    },
    model: {
      value: _vm.form.coastLineLength,
      callback: function ($$v) {
        _vm.$set(_vm.form, "coastLineLength", $$v);
      },
      expression: "form.coastLineLength"
    }
  })], 1) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-select', {
    staticStyle: {
      "margin-top": "12px !important",
      "max-width": "432px"
    },
    attrs: {
      "clearable": "",
      "items": _vm.neighbours,
      "outlined": "",
      "item-text": "name",
      "item-value": "value",
      "label": _vm.$t('Neighbours')
    },
    model: {
      value: _vm.form.neighbours,
      callback: function ($$v) {
        _vm.$set(_vm.form, "neighbours", $$v);
      },
      expression: "form.neighbours"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticStyle: {
      "padding": "32px 0 0 0",
      "max-width": "432px"
    },
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType != 'garage' ? _c('v-select', {
    staticStyle: {
      "margin-bottom": "-12px"
    },
    attrs: {
      "clearable": "",
      "items": _vm.buildings,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('Building1')
    },
    model: {
      value: _vm.form.building,
      callback: function ($$v) {
        _vm.$set(_vm.form, "building", $$v);
      },
      expression: "form.building"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' ? _c('v-select', {
    staticStyle: {
      "margin-bottom": "-12px"
    },
    attrs: {
      "clearable": "",
      "items": _vm.roads,
      "outlined": "",
      "item-text": "name",
      "item-value": "value",
      "label": _vm.$t('Roads')
    },
    model: {
      value: _vm.form.roads,
      callback: function ($$v) {
        _vm.$set(_vm.form, "roads", $$v);
      },
      expression: "form.roads"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' ? _c('v-select', {
    attrs: {
      "clearable": "",
      "items": _vm.locations,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('Location')
    },
    model: {
      value: _vm.form.location,
      callback: function ($$v) {
        _vm.$set(_vm.form, "location", $$v);
      },
      expression: "form.location"
    }
  }) : _vm._e(), _vm.form.estateType != 'commercial' && _vm.form.estateType != 'apartment' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "margin-top": "-8px !important"
    },
    attrs: {
      "id": "is-forest-near",
      "label": _vm.$t('ForestIsNear'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.forestIsNear,
      callback: function ($$v) {
        _vm.$set(_vm.form, "forestIsNear", $$v);
      },
      expression: "form.forestIsNear"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.form.sectionType == 'communication' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-col', {
    staticStyle: {
      "padding-top": "32px",
      "padding-bottom": "12px",
      "padding-left": "0px"
    },
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType != 'land' ? _c('v-select', {
    attrs: {
      "clearable": "",
      "items": _vm.securitySystems,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('SelectSecuritySystem')
    },
    model: {
      value: _vm.form.securitySystem,
      callback: function ($$v) {
        _vm.$set(_vm.form, "securitySystem", $$v);
      },
      expression: "form.securitySystem"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    attrs: {
      "id": "has-internet",
      "label": _vm.$t('Internet'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.internet,
      callback: function ($$v) {
        _vm.$set(_vm.form, "internet", $$v);
      },
      expression: "form.internet"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'commercial' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-phone",
      "label": _vm.$t('Phone'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.telephone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "telephone", $$v);
      },
      expression: "form.telephone"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'commercial' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-cable-tv",
      "label": _vm.$t('CableTv'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.cableTv,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cableTv", $$v);
      },
      expression: "form.cableTv"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-security-door",
      "label": _vm.$t('SecurityDoor'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.securityDoor,
      callback: function ($$v) {
        _vm.$set(_vm.form, "securityDoor", $$v);
      },
      expression: "form.securityDoor"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticStyle: {
      "padding-top": "44px",
      "padding-bottom": "0px",
      "padding-left": "20px",
      "padding-right": "0px"
    },
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    attrs: {
      "id": "is-staircase-locked",
      "label": _vm.$t('LockedStairway'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.staircaseLocked,
      callback: function ($$v) {
        _vm.$set(_vm.form, "staircaseLocked", $$v);
      },
      expression: "form.staircaseLocked"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-neighbourhood-watch",
      "label": _vm.$t('NeighbourhoodWatch'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.neighbourhoodSecurity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "neighbourhoodSecurity", $$v);
      },
      expression: "form.neighbourhoodSecurity"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-guard",
      "label": _vm.$t('Guard'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.guard,
      callback: function ($$v) {
        _vm.$set(_vm.form, "guard", $$v);
      },
      expression: "form.guard"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-video-surveillance",
      "label": _vm.$t('VideoSurveillance'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.videoSecurity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "videoSecurity", $$v);
      },
      expression: "form.videoSecurity"
    }
  }) : _vm._e(), _vm.form.estateType == 'garage' ? _c('v-checkbox', {
    attrs: {
      "id": "has-video-surveillance",
      "label": _vm.$t('VideoSurveillance'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.videoSecurity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "videoSecurity", $$v);
      },
      expression: "form.videoSecurity"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'apartment' && _vm.form.estateType != 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-fence",
      "label": _vm.$t('Fence'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.fence,
      callback: function ($$v) {
        _vm.$set(_vm.form, "fence", $$v);
      },
      expression: "form.fence"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.form.sectionType == 'heating' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-col', {
    staticClass: "additional-first-row",
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    attrs: {
      "id": "has-fireplace",
      "label": _vm.$t('Fireplace'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.firePlace,
      callback: function ($$v) {
        _vm.$set(_vm.form, "firePlace", $$v);
      },
      expression: "form.firePlace"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-central-gas",
      "label": _vm.$t('CentralGas'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.centralGas,
      callback: function ($$v) {
        _vm.$set(_vm.form, "centralGas", $$v);
      },
      expression: "form.centralGas"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-central-heating",
      "label": _vm.$t('CentralHeating'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.centralHeating,
      callback: function ($$v) {
        _vm.$set(_vm.form, "centralHeating", $$v);
      },
      expression: "form.centralHeating"
    }
  }) : _vm._e(), _vm.form.estateType == 'garage' ? _c('v-checkbox', {
    attrs: {
      "id": "has-central-heating",
      "label": _vm.$t('CentralHeating'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.centralHeating,
      callback: function ($$v) {
        _vm.$set(_vm.form, "centralHeating", $$v);
      },
      expression: "form.centralHeating"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-gas-heating",
      "label": _vm.$t('GasHeating'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.gasHeating,
      callback: function ($$v) {
        _vm.$set(_vm.form, "gasHeating", $$v);
      },
      expression: "form.gasHeating"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-furnace-heating",
      "label": _vm.$t('FurnaceHeating'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.furnaceHeating,
      callback: function ($$v) {
        _vm.$set(_vm.form, "furnaceHeating", $$v);
      },
      expression: "form.furnaceHeating"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-electrical-heating",
      "label": _vm.$t('ElectricalHeating'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.electricalHeating,
      callback: function ($$v) {
        _vm.$set(_vm.form, "electricalHeating", $$v);
      },
      expression: "form.electricalHeating"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-solid-fuel",
      "label": _vm.$t('SolidFuel'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.solidFuel,
      callback: function ($$v) {
        _vm.$set(_vm.form, "solidFuel", $$v);
      },
      expression: "form.solidFuel"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "additional-second-row",
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType != 'land' ? _c('v-checkbox', {
    attrs: {
      "id": "has-combined-heating",
      "label": _vm.$t('CombinedHeating'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.combinedHeating,
      callback: function ($$v) {
        _vm.$set(_vm.form, "combinedHeating", $$v);
      },
      expression: "form.combinedHeating"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-geothermal-heating",
      "label": _vm.$t('GeothermalHeating'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.geothermalHeating,
      callback: function ($$v) {
        _vm.$set(_vm.form, "geothermalHeating", $$v);
      },
      expression: "form.geothermalHeating"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-air-conditioner",
      "label": _vm.$t('AirConditioner'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.conditioner,
      callback: function ($$v) {
        _vm.$set(_vm.form, "conditioner", $$v);
      },
      expression: "form.conditioner"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-floor-heating",
      "label": _vm.$t('FloorHeating'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.floorHeating,
      callback: function ($$v) {
        _vm.$set(_vm.form, "floorHeating", $$v);
      },
      expression: "form.floorHeating"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-air-heat-pump",
      "label": _vm.$t('AirHeatPump'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.airHeatPump,
      callback: function ($$v) {
        _vm.$set(_vm.form, "airHeatPump", $$v);
      },
      expression: "form.airHeatPump"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-air-water-pump",
      "label": _vm.$t('AirWaterHeatPump'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.airWaterPump,
      callback: function ($$v) {
        _vm.$set(_vm.form, "airWaterPump", $$v);
      },
      expression: "form.airWaterPump"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'apartment' && _vm.form.estateType != 'commercial' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-liquid-fuel",
      "label": _vm.$t('LiquidFuel'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.liquidFuel,
      callback: function ($$v) {
        _vm.$set(_vm.form, "liquidFuel", $$v);
      },
      expression: "form.liquidFuel"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.form.sectionType == 'kitchen' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-col', {
    staticClass: "additional-first-row",
    staticStyle: {
      "padding-bottom": "12px !important"
    },
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType !== 'land' && _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "0px",
      "margin-top": "-2px !important"
    },
    attrs: {
      "id": "has-electrical-stove",
      "label": _vm.$t('ElectricalStove'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.electricalStove,
      callback: function ($$v) {
        _vm.$set(_vm.form, "electricalStove", $$v);
      },
      expression: "form.electricalStove"
    }
  }) : _vm._e(), _vm.form.estateType !== 'land' && _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px",
      "margin-top": "-2px !important"
    },
    attrs: {
      "id": "has-gas-stove",
      "label": _vm.$t('GasStove'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.gasStove,
      callback: function ($$v) {
        _vm.$set(_vm.form, "gasStove", $$v);
      },
      expression: "form.gasStove"
    }
  }) : _vm._e(), _vm.form.estateType !== 'land' && _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px",
      "margin-top": "-2px !important"
    },
    attrs: {
      "id": "has-ceramic-stove",
      "label": _vm.$t('CeramicStove'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.ceramicStove,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ceramicStove", $$v);
      },
      expression: "form.ceramicStove"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px",
      "margin-top": "-2px !important"
    },
    attrs: {
      "id": "has-open-kitchen",
      "label": _vm.$t('OpenKitchen'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.openedKitchen,
      callback: function ($$v) {
        _vm.$set(_vm.form, "openedKitchen", $$v);
      },
      expression: "form.openedKitchen"
    }
  }) : _vm._e(), _vm.form.estateType == 'commercial' ? _c('v-checkbox', {
    attrs: {
      "id": "has-kitchen",
      "label": _vm.$t('Kitchen'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.kitchen,
      callback: function ($$v) {
        _vm.$set(_vm.form, "kitchen", $$v);
      },
      expression: "form.kitchen"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'house' && _vm.form.estateType != 'house_part' && _vm.form.estateType != 'cottage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-fridge",
      "label": _vm.$t('Fridge'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.fridge,
      callback: function ($$v) {
        _vm.$set(_vm.form, "fridge", $$v);
      },
      expression: "form.fridge"
    }
  }) : _vm._e(), _vm.form.estateType == 'house' || _vm.form.estateType == 'house_part' || _vm.form.estateType == 'cottage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-fridge",
      "label": _vm.$t('Fridge'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.fridge,
      callback: function ($$v) {
        _vm.$set(_vm.form, "fridge", $$v);
      },
      expression: "form.fridge"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-kitchen-furniture",
      "label": _vm.$t('KitchenFurniture'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.kitchenFurniture,
      callback: function ($$v) {
        _vm.$set(_vm.form, "kitchenFurniture", $$v);
      },
      expression: "form.kitchenFurniture"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "additional-second-row",
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType !== 'land' && _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "0px"
    },
    attrs: {
      "id": "has-firewood-stove",
      "label": _vm.$t('FireWoodStove'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.fireWoodStove,
      callback: function ($$v) {
        _vm.$set(_vm.form, "fireWoodStove", $$v);
      },
      expression: "form.fireWoodStove"
    }
  }) : _vm._e(), _vm.form.estateType !== 'land' && _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "22px"
    },
    attrs: {
      "id": "has-induction-stove",
      "label": _vm.$t('InductionHob'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.inductionStove,
      callback: function ($$v) {
        _vm.$set(_vm.form, "inductionStove", $$v);
      },
      expression: "form.inductionStove"
    }
  }) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "section"
  }, [_c('v-row', [_c('div', {
    staticClass: "circle-number"
  }, [_vm._v("5")]), _c('h5', [_vm._v(_vm._s(_vm.$t("LanguageSpecificInfo")))])]), _c('v-row', {
    staticClass: "box-wrapper",
    staticStyle: {
      "padding-top": "20px"
    }
  }, _vm._l(_vm.languageTypes, function (languageType) {
    return _c('button', {
      key: languageType.name,
      class: {
        'big-box1': !languageType.isChosen,
        'big-box1-white active-box': languageType.isChosen
      },
      attrs: {
        "item-text": "name",
        "item-value": "value"
      },
      on: {
        "click": function ($event) {
          return _vm.chooseBox3(languageType.value);
        }
      }
    }, [_c('v-row', {
      staticStyle: {
        "align-items": "center"
      }
    }, [_vm._v(" " + _vm._s(languageType.name) + " ")])], 1);
  }), 0), _vm.form.languageType === 'estonian' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Slogan")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "16px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "slogan"
    },
    model: {
      value: _vm.form.slogan,
      callback: function ($$v) {
        _vm.$set(_vm.form, "slogan", $$v);
      },
      expression: "form.slogan"
    }
  })], 1), _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ObjectDescription")))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.description,
      expression: "form.description"
    }],
    attrs: {
      "id": "textarea",
      "name": "textarea"
    },
    domProps: {
      "value": _vm.form.description
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "description", $event.target.value);
      }, function ($event) {
        return _vm.replaceSymbols('description');
      }]
    }
  })]), _c('v-row', {
    staticStyle: {
      "gap": "8px"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-link",
      "placeholder": _vm.$t('AdditionalInfoLink')
    },
    model: {
      value: _vm.form.additionalInfoLink,
      callback: function ($$v) {
        _vm.$set(_vm.form, "additionalInfoLink", $$v);
      },
      expression: "form.additionalInfoLink"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "link-title",
      "placeholder": _vm.$t('LinkTitle')
    },
    model: {
      value: _vm.form.linkTitle,
      callback: function ($$v) {
        _vm.$set(_vm.form, "linkTitle", $$v);
      },
      expression: "form.linkTitle"
    }
  })], 1), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "padding-top": "32px !important"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ServicesInNeighbourhood")))])]) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "services-in-neighbourhood"
    },
    model: {
      value: _vm.form.servicesInNeighbourhood,
      callback: function ($$v) {
        _vm.$set(_vm.form, "servicesInNeighbourhood", $$v);
      },
      expression: "form.servicesInNeighbourhood"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.form.languageType == 'english' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Slogan")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "16px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-link"
    },
    model: {
      value: _vm.form.sloganENG,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sloganENG", $$v);
      },
      expression: "form.sloganENG"
    }
  })], 1), _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ObjectDescription")))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.descriptionENG,
      expression: "form.descriptionENG"
    }],
    attrs: {
      "id": "textarea",
      "name": "textarea"
    },
    domProps: {
      "value": _vm.form.descriptionENG
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "descriptionENG", $event.target.value);
      }, function ($event) {
        return _vm.replaceSymbols('descriptionENG');
      }]
    }
  })]), _c('v-row', {
    staticStyle: {
      "gap": "8px"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-link-eng",
      "placeholder": _vm.$t('AdditionalInfoLink')
    },
    model: {
      value: _vm.form.additionalInfoLinkENG,
      callback: function ($$v) {
        _vm.$set(_vm.form, "additionalInfoLinkENG", $$v);
      },
      expression: "form.additionalInfoLinkENG"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "link-title-eng",
      "placeholder": _vm.$t('LinkTitle')
    },
    model: {
      value: _vm.form.linkTitleENG,
      callback: function ($$v) {
        _vm.$set(_vm.form, "linkTitleENG", $$v);
      },
      expression: "form.linkTitleENG"
    }
  })], 1), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "padding-top": "32px !important"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ServicesInNeighbourhood")))])]) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "services-in-neighbourhood-eng"
    },
    model: {
      value: _vm.form.servicesInNeighbourhoodENG,
      callback: function ($$v) {
        _vm.$set(_vm.form, "servicesInNeighbourhoodENG", $$v);
      },
      expression: "form.servicesInNeighbourhoodENG"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.form.languageType == 'russian' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Slogan")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "16px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-ru"
    },
    model: {
      value: _vm.form.sloganRU,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sloganRU", $$v);
      },
      expression: "form.sloganRU"
    }
  })], 1), _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ObjectDescription")))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.descriptionRU,
      expression: "form.descriptionRU"
    }],
    attrs: {
      "id": "textarea",
      "name": "textarea"
    },
    domProps: {
      "value": _vm.form.descriptionRU
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "descriptionRU", $event.target.value);
      }, function ($event) {
        return _vm.replaceSymbols('descriptionRU');
      }]
    }
  })]), _c('v-row', {
    staticStyle: {
      "gap": "8px"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-link-ru",
      "placeholder": _vm.$t('AdditionalInfoLink')
    },
    model: {
      value: _vm.form.additionalInfoLinkRU,
      callback: function ($$v) {
        _vm.$set(_vm.form, "additionalInfoLinkRU", $$v);
      },
      expression: "form.additionalInfoLinkRU"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "link-title-ru",
      "placeholder": _vm.$t('LinkTitle')
    },
    model: {
      value: _vm.form.linkTitleRU,
      callback: function ($$v) {
        _vm.$set(_vm.form, "linkTitleRU", $$v);
      },
      expression: "form.linkTitleRU"
    }
  })], 1), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "padding-top": "32px !important"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ServicesInNeighbourhood")))])]) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-ru"
    },
    model: {
      value: _vm.form.servicesInNeighbourhoodRU,
      callback: function ($$v) {
        _vm.$set(_vm.form, "servicesInNeighbourhoodRU", $$v);
      },
      expression: "form.servicesInNeighbourhoodRU"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.form.languageType == 'finnish' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Slogan")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "16px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-fi"
    },
    model: {
      value: _vm.form.sloganFI,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sloganFI", $$v);
      },
      expression: "form.sloganFI"
    }
  })], 1), _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ObjectDescription")))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.descriptionFI,
      expression: "form.descriptionFI"
    }],
    attrs: {
      "id": "textarea",
      "name": "textarea"
    },
    domProps: {
      "value": _vm.form.descriptionFI
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "descriptionFI", $event.target.value);
      }, function ($event) {
        return _vm.replaceSymbols('descriptionFI');
      }]
    }
  })]), _c('v-row', {
    staticStyle: {
      "gap": "8px"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-link-ru",
      "placeholder": _vm.$t('AdditionalInfoLink')
    },
    model: {
      value: _vm.form.additionalInfoLinkFI,
      callback: function ($$v) {
        _vm.$set(_vm.form, "additionalInfoLinkFI", $$v);
      },
      expression: "form.additionalInfoLinkFI"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "link-title-ru",
      "placeholder": _vm.$t('LinkTitle')
    },
    model: {
      value: _vm.form.linkTitleFI,
      callback: function ($$v) {
        _vm.$set(_vm.form, "linkTitleFI", $$v);
      },
      expression: "form.linkTitleFI"
    }
  })], 1), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "padding-top": "32px !important"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ServicesInNeighbourhood")))])]) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-fi"
    },
    model: {
      value: _vm.form.servicesInNeighbourhoodFI,
      callback: function ($$v) {
        _vm.$set(_vm.form, "servicesInNeighbourhoodFI", $$v);
      },
      expression: "form.servicesInNeighbourhoodFI"
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1), _c('div', {
    staticClass: "section"
  }, [_c('v-row', [_c('div', {
    staticClass: "circle-number"
  }, [_vm._v("6")]), _c('h5', [_vm._v(_vm._s(_vm.$t("Media")))])]), _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Pictures")))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [!_vm.edit ? _c('UploadImages', {
    staticClass: "image-uploader",
    attrs: {
      "Imgs": [],
      "is-visible": true,
      "clearAll": _vm.$t('ClearAll')
    },
    on: {
      "changed": function ($event) {
        return _vm.putImages($event);
      }
    }
  }) : _vm._e(), _vm.edit ? [((_vm$form$meta = _vm.form.meta) === null || _vm$form$meta === void 0 ? void 0 : (_vm$form$meta$images = _vm$form$meta.images) === null || _vm$form$meta$images === void 0 ? void 0 : (_vm$form$meta$images$ = _vm$form$meta$images.status) === null || _vm$form$meta$images$ === void 0 ? void 0 : _vm$form$meta$images$.original) !== 'uploading' && ((_vm$form$meta2 = _vm.form.meta) === null || _vm$form$meta2 === void 0 ? void 0 : (_vm$form$meta2$images = _vm$form$meta2.images) === null || _vm$form$meta2$images === void 0 ? void 0 : (_vm$form$meta2$images2 = _vm$form$meta2$images.status) === null || _vm$form$meta2$images2 === void 0 ? void 0 : _vm$form$meta2$images2.original) !== 'pending' ? _c('UploadImages', {
    staticClass: "image-uploader",
    attrs: {
      "Imgs2": _vm.form.images,
      "is-visible": true,
      "clearAll": _vm.$t('ClearAll')
    },
    on: {
      "changed": function ($event) {
        return _vm.putImages($event);
      }
    }
  }) : _c('div', [((_vm$form$meta3 = _vm.form.meta) === null || _vm$form$meta3 === void 0 ? void 0 : (_vm$form$meta3$images = _vm$form$meta3.images) === null || _vm$form$meta3$images === void 0 ? void 0 : (_vm$form$meta3$images2 = _vm$form$meta3$images.status) === null || _vm$form$meta3$images2 === void 0 ? void 0 : _vm$form$meta3$images2.original) === 'uploading' || ((_vm$form$meta4 = _vm.form.meta) === null || _vm$form$meta4 === void 0 ? void 0 : (_vm$form$meta4$images = _vm$form$meta4.images) === null || _vm$form$meta4$images === void 0 ? void 0 : (_vm$form$meta4$images2 = _vm$form$meta4$images.status) === null || _vm$form$meta4$images2 === void 0 ? void 0 : _vm$form$meta4$images2.original) === 'pending' ? _c('div', {
    staticClass: "listing-images-updating"
  }, [_c('img', {
    attrs: {
      "height": "20",
      "width": "20",
      "src": require('@/assets/images/alert.svg'),
      "alt": "info"
    }
  }), ((_vm$form$meta5 = _vm.form.meta) === null || _vm$form$meta5 === void 0 ? void 0 : (_vm$form$meta5$images = _vm$form$meta5.images) === null || _vm$form$meta5$images === void 0 ? void 0 : _vm$form$meta5$images.count) - (((_vm$form$images = _vm.form.images) === null || _vm$form$images === void 0 ? void 0 : _vm$form$images.length) || 0) > 0 ? _c('p', [_vm._v(" " + _vm._s(((_vm$form$meta6 = _vm.form.meta) === null || _vm$form$meta6 === void 0 ? void 0 : (_vm$form$meta6$images = _vm$form$meta6.images) === null || _vm$form$meta6$images === void 0 ? void 0 : _vm$form$meta6$images.count) - (((_vm$form$images2 = _vm.form.images) === null || _vm$form$images2 === void 0 ? void 0 : _vm$form$images2.length) || 0)) + " " + _vm._s(_vm.$tc('ImagesAreCurrentlyBeingUploaded', ((_vm$form$meta7 = _vm.form.meta) === null || _vm$form$meta7 === void 0 ? void 0 : (_vm$form$meta7$images = _vm$form$meta7.images) === null || _vm$form$meta7$images === void 0 ? void 0 : _vm$form$meta7$images.count) - (((_vm$form$images3 = _vm.form.images) === null || _vm$form$images3 === void 0 ? void 0 : _vm$form$images3.length) || 0))) + " ")]) : _c('p', [_vm._v(_vm._s(_vm.$t('ImagesAreBeingUpdated')))])]) : _vm._e()])] : _vm._e()], 2), _c('v-row', {
    staticStyle: {
      "width": "100%",
      "height": "52px",
      "display": "flex",
      "flex-wrap": "nowrap",
      "gap": "16px",
      "align-items": "center",
      "white-space": "nowrap"
    }
  }, [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ApplyWatermarkToImages')
    },
    on: {
      "click": function ($event) {
        return _vm.changeWatermarkStatus($event);
      }
    },
    model: {
      value: _vm.form.isWatermarkApplied,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isWatermarkApplied", $$v);
      },
      expression: "form.isWatermarkApplied"
    }
  }), _vm.form.isWatermarkApplied && _vm.companyWatermarkSets.length > 0 ? _c('div', {
    staticStyle: {
      "width": "284px"
    }
  }, [_c('BaseDropdown', {
    attrs: {
      "items": _vm.companyWatermarkSets,
      "dropdown-offset-y": 60,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('Watermark')
    },
    model: {
      value: _vm.form.appliedWatermarkSetId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "appliedWatermarkSetId", $$v);
      },
      expression: "form.appliedWatermarkSetId"
    }
  })], 1) : _vm._e()], 1), _c('v-row', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset",
      "width": "100%"
    },
    attrs: {
      "id": "video-link",
      "placeholder": _vm.$t('VideoLink')
    },
    model: {
      value: _vm.form.videoLink,
      callback: function ($$v) {
        _vm.$set(_vm.form, "videoLink", $$v);
      },
      expression: "form.videoLink"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset",
      "width": "100%"
    },
    attrs: {
      "id": "Virtual-tour-link",
      "placeholder": _vm.$t('VirtualTourLink')
    },
    model: {
      value: _vm.form.virtualTourLink,
      callback: function ($$v) {
        _vm.$set(_vm.form, "virtualTourLink", $$v);
      },
      expression: "form.virtualTourLink"
    }
  })], 1)], 1), _c('div', {
    class: {
      'buttons-row justify-end': !_vm.edit,
      'buttons-row justify-between': _vm.edit
    }
  }, [_vm.edit ? _c('div', [this.form.statusCode === 1 ? _c('button', {
    staticClass: "delete-button",
    attrs: {
      "id": "listing-delete",
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": _vm.openDeleteModal
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/TrashBin.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")]) : _vm._e(), this.form.status === 'archived' ? _c('button', {
    staticClass: "delete-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": _vm.openDeletePermanentlyModal
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/TrashBin.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "flex-wrap": "nowrap",
      "gap": "12px"
    }
  }, [_c('button', {
    staticClass: "cancel padding-top",
    staticStyle: {
      "padding-left": "12px",
      "padding-right": "12px"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.back();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('div', {
    staticClass: "cancel",
    staticStyle: {
      "margin-right": "12px",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.saveListing(_vm.form, false);
      }
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "30px",
      "height": "30px"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('p', [_vm._v(_vm._s(_vm.$t("Save")))]) : _vm._e()]), _c('div', {
    staticClass: "add-button",
    attrs: {
      "id": "save-button"
    },
    on: {
      "click": function ($event) {
        return _vm.saveListing(_vm.form, true);
      }
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "30px",
      "height": "30px"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('img', {
    staticStyle: {
      "margin-right": "9px"
    },
    attrs: {
      "alt": "",
      "src": require("../../assets/images/checkmarkCircled.svg")
    }
  }) : _vm._e(), !_vm.isLoading ? _c('p', [_vm._v(_vm._s(_vm.$t("SaveAndExit")))]) : _vm._e()])])])]) : _vm._e()]), _vm.deleteModalOpen ? _c('DeleteModal', {
    staticStyle: {
      "z-index": "121"
    },
    attrs: {
      "removing": false
    },
    on: {
      "canceled": function ($event) {
        _vm.deleteModalOpen = false;
      },
      "approved": function ($event) {
        return _vm.deleteListing();
      }
    }
  }) : _vm._e(), _vm.deletePermanentlyModalOpen ? _c('DeleteModal', {
    staticStyle: {
      "z-index": "121"
    },
    attrs: {
      "removing": false
    },
    on: {
      "canceled": function ($event) {
        _vm.deletePermanentlyModalOpen = false;
      },
      "approved": function ($event) {
        return _vm.deleteListingPermanently();
      }
    }
  }) : _vm._e(), _vm.statusesOpen ? _c('StatusDropdown', {
    attrs: {
      "position": _vm.position,
      "listing": _vm.form,
      "multi": false
    },
    on: {
      "closed": function ($event) {
        _vm.statusesOpen = false;
      },
      "statusChanged": _vm.changeListingStatus,
      "open-booking-modal": _vm.openBookingDetailsModal
    }
  }) : _vm._e(), _vm.bookingDetailsModalOpen ? _c('BookingDetailsModal', {
    attrs: {
      "opened-listing": _vm.bookedListing
    },
    on: {
      "update-listing": _vm.changeListingStatus,
      "close-modal": function ($event) {
        _vm.bookingDetailsModalOpen = false;
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }